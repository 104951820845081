/* global axios */
import ApiClient from './ApiClient';

class CustomAttributesReportsAPI extends ApiClient {
  constructor() {
    super('custom_attributes_responses', { accountScoped: true });
  }

  download({ label, locale } = {}) {
    return axios.get(`${this.url}/download`, {
      params: {
        label,
        locale,
      },
    });
  }

  getMetrics({ label } = {}) {
    return axios.get(`${this.url}`, {
      params: { label },
    });
  }

  show({ id, label }) {
    if (!id) return Promise.resolve({ custom_attribute: {}, records: [] });
    return axios.get(`${this.url}/${id}`, {
      params: { label },
    });
  }
}

export default new CustomAttributesReportsAPI();
