import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import * as XLSX from 'xlsx';

export const downloadXlsxFile = (fileName, content) => {
  const arrayOfArrayCsv = content.split('\n').map(row => {
    return row.split(';');
  });

  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
  XLSX.utils.book_append_sheet(wb, newWs);
  return XLSX.writeFile(wb, fileName);
};
export const downloadCsvFile = (fileName, content) => {
  if (fileName.endsWith('xlsx')) {
    return downloadXlsxFile(fileName, content);
  }
  const contentType = 'data:text/csv;charset=utf-8;';
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('download', fileName);
  link.setAttribute('href', url);
  link.click();
  return link;
};

export const generateFileName = ({
  type,
  to,
  businessHours = false,
  fileFormat = 'csv',
}) => {
  let name = `${type}-report-${format(fromUnixTime(to), 'dd-MM-yyyy')}`;
  if (businessHours) {
    name = `${name}-business-hours`;
  }
  return `${name}.${fileFormat}`;
};
